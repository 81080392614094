













import Vue from 'vue';
import { isHTTPError } from '../../libs/http';
import {
  httpErrorToOAuth2Error,
  isOAuth2Exception,
  OAuth2ErrorResponse,
  OAuth2Exception,
  OAuth2PKCEService,
  OAuth2Service,
  OAuth2AuthorizationRequest,
  OAuth2StrategyLinkState,
} from '../../libs/oauth2';
import {
  Strategy,
  GitHubStrategy,
  GoogleOAuth2Strategy,
  WindowsLiveStrategy,
} from '../../libs/strategies';

export default Vue.extend({
  data() {
    const response = OAuth2Service.getAuthorizationResponseFromRoute(
      this.$route,
    );

    const state = OAuth2Service.getStrategyState(response.state as string);

    return {
      response,
      state,
      request: OAuth2Service.getStrategySession(
        state.application_client_id,
        state.strategy,
      ),
      pkce: OAuth2PKCEService.getPKCEChallengeByClientID(
        state.application_client_id,
        state.strategy,
      ),
    };
  },

  async mounted() {
    try {
      switch (this.state.type) {
        case 'link': {
          await this.link(this.state.strategy);
          break;
        }

        case 'authorize':
        default: {
          await this.authorize(this.state.strategy);
        }
      }
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    async authorize(strategy: string) {
      // Authorize via strategy
      try {
        switch (strategy) {
          case 'google-oauth2': {
            await GoogleOAuth2Strategy.authorize(
              this.state.application_client_id,
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
            break;
          }

          case 'windowslive': {
            await WindowsLiveStrategy.authorize(
              this.state.application_client_id,
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
            break;
          }

          case 'github': {
            await GitHubStrategy.authorize(
              this.state.application_client_id,
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
            break;
          }

          default: {
            throw new OAuth2Exception(
              'unsupported_grant_type',
              'Strategy unknown',
            );
          }
        }

        // Perform authorization request
        const authorization = await OAuth2Service.authorize(
          this.request as OAuth2AuthorizationRequest,
        );

        // Redirect back to redirect URI
        OAuth2Service.redirectToURI(authorization, this.request?.redirect_uri);
      } catch (e) {
        return this.handleOAuth2Error(e);
      }
    },

    async link(strategy: string) {
      const state = this.state as OAuth2StrategyLinkState;

      // Link strategy to resource
      try {
        // First, link based on strategy
        switch (strategy) {
          case 'google-oauth2': {
            await GoogleOAuth2Strategy.link(
              this.state.application_client_id,
              {
                token: state.token,
                resource: state.resource,
              },
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
            break;
          }

          case 'windowslive': {
            await WindowsLiveStrategy.link(
              this.state.application_client_id,
              {
                token: state.token,
                resource: state.resource,
              },
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
            break;
          }

          default: {
            await Strategy.link(
              strategy,
              this.state.application_client_id,
              {
                token: state.token,
                resource: state.resource,
              },
              this.state.client_id,
              this.response,
              {
                code_verifier: this.pkce.code_verifier,
              },
            );
          }
        }

        // Perform authorization request
        const authorization = await OAuth2Service.authorize(
          this.request as OAuth2AuthorizationRequest,
        );

        // Redirect back to redirect URI
        OAuth2Service.redirectToURI(authorization, this.request?.redirect_uri);
      } catch (e) {
        return this.handleOAuth2Error(e);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleOAuth2Error(e?: any) {
      // Set default error
      const oauth2Error: OAuth2ErrorResponse = {
        error: 'server_error',
        error_description: 'Internal server error',
      };

      // Handle if HTTP error
      if (isHTTPError(e) === true) {
        const httpOAuth2Error = httpErrorToOAuth2Error(e);
        oauth2Error.error = httpOAuth2Error.error;
        oauth2Error.error_description = httpOAuth2Error.error_description;
        oauth2Error.state = httpOAuth2Error.state;
      }

      if (isOAuth2Exception(e) === true) {
        const oauth2Exception: OAuth2Exception = e;
        oauth2Error.error = oauth2Exception.oauth2.error;
        oauth2Error.error_description =
          oauth2Exception.oauth2.error_description;

        oauth2Error.state = oauth2Exception.oauth2.state;
      }

      // TODO: Save error to Sentry or some sort
      console.error(e);
      OAuth2Service.redirectToURI(oauth2Error, this.request?.redirect_uri);
    },
  },
});
